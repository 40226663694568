import Page from '@components/Layout/Page';
import Link from '@components/Link';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const EventsPage: React.FC = () => {
	const data = useStaticQuery(graphql`
		{
			allLiveWorkshopsYaml {
				nodes {
					id
					title
					description
					author
				}
			}
			allLiveSideEventsYaml {
				nodes {
					id
					title
					description
				}
			}
		}
	`);
	const workshops = data.allLiveWorkshopsYaml.nodes;
	const sideEvents = data.allLiveSideEventsYaml.nodes;
	return (
		<Page title="Live Events" live>
			<h1 className="font-bold text-6xl">Events</h1>
			<p>
				Check out the{' '}
				<Link className="font-bold text-primary-500" to="/live/schedule">
					schedule
				</Link>{' '}
				to know when all these workshops and more are happening!
			</p>
			<h2 className="font-bold text-2xl my-3">Workshops</h2>
			<div className="flex flex-col">
				{workshops.map((item) => (
					<div className="list-none p-3 border border-white" key={item.id}>
						<h1 className="text-2xl font-bold">{item.title}</h1>
						<h2 className="text-xl font-bold mb-2">by {item.author}</h2>
						<p className="text-md">{item.description}</p>
					</div>
				))}
			</div>

			<h2 className="font-bold text-6xl my-5">Side Events</h2>
			<div className="flex flex-col">
				{sideEvents.map((item) => (
					<div key={item.id} className="list-none p-3 border border-white">
						<h1 className="text-2xl font-bold">{item.title}</h1>
						<p className="text-md">{item.description}</p>
					</div>
				))}
			</div>
		</Page>
	);
};

export default EventsPage;
